import React, { Component, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/TempAuthPage.css';
import './css/App.css';
import './css/QuoteTable.css';
import AppProvider from "./context/AppProvider";
import Cookies from 'universal-cookie';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { WelcomePage } from "./components/pages/WelcomePage";
import QuotePage from "./components/pages/QuotePage";
import SaturnStoresPage from './components/pages/SaturnStoresPage';
import ErrorBoundaryWrapper from "./components/molecules/ErrorBoundary";
import ErrorNotification from "./components/atoms/ErrorNotification";
import ErrorButton from "./components/atoms/ErrorButton";
import QuoteToolClient from "@kjdelectronics/ps-quotetool-domain/service/QuoteToolClient";
import CreateAccountPage from "./components/pages/CreateAccountPage.js";
import ForgotPasswordForm from "./components/atoms/ForgotPasswordForm";
import QuoteTable from "./components/molecules/QuoteTable";
import AccountsPage from "./components/pages/AccountsPage";
import quoteToolClient from "@kjdelectronics/ps-quotetool-domain/service/QuoteToolClient";
import {getAuthCookieName} from "./helper/auth.helper.js";
import {ToastContainer} from "react-toastify";
import LoginPage from "./components/pages/LoginPage.js";
import LoadingPage from "./components/pages/LoadingPage.js";
import AccountPage from "./components/pages/AccountPage.js";
import QuotesPage from "./components/pages/QuotesPage.js";
import UserPendingPage from "./components/pages/UserPendingPage.js";
import {FALLBACK_CURRENCY_RATES} from "@kjdelectronics/ps-quotetool-domain/obj/quote/CurrencyRates.js";

const cookies = new Cookies();
const AUTH_COOKIE_NAME= getAuthCookieName();

//User that do not have this role will not be granted access to the app
const APP_ACCESS_ROLE_NAME = "sales";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stores: null,
            countryData: null,
            currencyRates: FALLBACK_CURRENCY_RATES,
            useCache: true,
            showErrorButton: false,
            initCalled: false,
            isClientReady: false,
        };
    }

    async init() {
        try {
            const stores = await this.quoteToolClient.getSaturnStores();
            const countries = await this.quoteToolClient.getSaturnCountries();
            const subdivisions = await this.quoteToolClient.getSaturnCountrySubdivisions();
            const currencyRates = await this.quoteToolClient.getSaturnCurrencyRates();

            const countryData = {
                countries,
                countrySubdivisions: subdivisions,
                lastUpdated: new Date().toISOString(),
            };

            this.setState({ stores, countryData, currencyRates });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    componentDidMount() {
        const sessionKey = cookies.get(AUTH_COOKIE_NAME);
        console.log("Session Key on load in App.js:", sessionKey);

        if (!sessionKey && !window.location.pathname.startsWith('/auth')) {
            window.location.replace("/auth/login");
            return;
        }

        if (sessionKey) {
            this.initApp();
        }

        // Add event listeners if necessary
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('beforeunload', this.handleBeforeUnload);

        this.setState({ isClientReady: true });
    }

    get quoteToolClient(){
        if(!cookies.get(AUTH_COOKIE_NAME)) {
            console.error(`Cannot get quote tool client without auth token`);
            return null;
        }

        return new QuoteToolClient({
            //If localhost use localhost API, otherwise do not provide a baseUrl to hit env at same URL
            baseUrl: window.location.hostname === "localhost" ? "http://localhost:3001/v1" : null,
            token: cookies.get(AUTH_COOKIE_NAME),
            unauthorizedCallback: () =>  {
                if(window.location.pathname !== "/auth/login")
                    window.location.replace("/auth/login");
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    initApp = async () => {
        try {
            await this.init();
            console.log("App initialized successfully");
        } catch (error) {
            console.error("Error during initialization:", error);
        }
    };

    handleKeyDown = (event) => {
        if (event.key === 'Pause') {
            this.setState(prevState => ({ showErrorButton: !prevState.showErrorButton }));
        }
    };

    // Logic to clear locally stored termsOptions on Control + Shift + R
    handleBeforeUnload = (event) => {
        if (event.ctrlKey && event.shiftKey && event.key === 'R') {
            localStorage.removeItem('termsOptions');
        }
    };

    redirectToLogin(){
        if (!window.location.pathname.startsWith("/auth")) {
            window.location.replace("/auth/login");
            return <div style={{textAlign: "center", verticalAlign: "middle"}}><h4 style={{marginTop: "25%"}}>Redirecting to login...</h4></div>
        }
    }

    render() {
        const { stores, countryData, showErrorButton, currencyRates } = this.state;

        const jsonWebToken = cookies.get(AUTH_COOKIE_NAME);

        if (window.location.pathname.startsWith("/v1"))
            window.location.href = "http://localhost:3001" + window.location.pathname;

        if (window.location.pathname.startsWith("/auth")) {
            return (
                <BrowserRouter>
                    <Routes>

                        <Route
                            path="/auth/login"
                            element={<LoginPage/>}
                        />
                        <Route
                            path="/auth/create-account"
                            element={<CreateAccountPage />}
                        />
                        <Route
                            path="/auth/forgot-password"
                            element={<ForgotPasswordForm  />}
                        />
                        <Route
                            path="*"
                            element={<Navigate to="/auth/login" />}
                        />
                    </Routes>
                    <ToastContainer />
                </BrowserRouter>
            );
        }

        if (!jsonWebToken)
            return this.redirectToLogin();

        const user = parseJwt(jsonWebToken);
        if(!user || !user.hasOwnProperty("roles")) //There was a json web token but it is not valid
            return this.redirectToLogin();

        //User is logged in but does not have access to the app
        if(!user.roles.includes(APP_ACCESS_ROLE_NAME))
            return <UserPendingPage/>

        if(!quoteToolClient)
            return <LoadingPage/>;

        if (!countryData || !stores) {
            return <LoadingPage/>;
        }


        return (
            <AppProvider user={user} countryData={countryData} saturnStores={stores} currencyRates={currencyRates}>
                <BrowserRouter>
                    <ErrorBoundaryWrapper>
                        <ErrorNotification />
                        {showErrorButton && <ErrorButton />}
                        <Routes>
                            <Route
                                path="/"
                                element={
                                <WelcomePage
                                    exampleBool={true}
                                />}
                            />
                            <Route
                                path="/stores"
                                element={
                                <SaturnStoresPage
                                    quoteToolClient={this.quoteToolClient}
                                />}
                            />
                            <Route
                                path="/accounts"
                                element={
                                <AccountsPage
                                />}
                            />
                            <Route
                                path="/accounts/:accountId"
                                element={
                                    <AccountPage
                                     quoteToolClient={this.quoteToolClient}/>}
                            />
                            <Route
                                path="/stores/:storeId/quotes/:quoteRef?"
                                element={
                                    <QuotePage
                                        quoteToolClient={this.quoteToolClient}
                                    />}
                            />
                            <Route
                                path="/quotations"
                                element={
                                    <QuotesPage
                                        quoteToolClient={this.quoteToolClient}
                                    />}
                            />
                            <Route
                                path="*"
                                element={<Navigate to="/" />}
                            />
                        </Routes>
                    </ErrorBoundaryWrapper>
                </BrowserRouter>
                <ToastContainer />
            </AppProvider>
        );
    }
}

function parseJwt (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}


/* Dark Mode WIP do not activate without changing the way the colors invert
const AppWithDarkMode = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        setIsDarkMode(darkModeMediaQuery.matches);

        const handleChange = (e) => {
            setIsDarkMode(e.matches);
        };

        darkModeMediaQuery.addEventListener('change', handleChange);
        return () => darkModeMediaQuery.removeEventListener('change', handleChange);
    }, []);

    return (
        <div className={isDarkMode ? 'dark-mode' : ''}>
            <App />
        </div>
    );
};

Use when ready: export default AppWithDarkMode;

 */

export default App;