import React from 'react';
import PropTypes from 'prop-types';
import QuoteLineItemRow from './QuoteLineItemRow';
import { Button, Input, InputGroup, InputGroupText } from "reactstrap";
import {usdFormatter} from "../../helper/formatters.js";
import NumericInput from "../atoms/NumericInput";

class ProductForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            discountValue: this.props.quote.totalsDiscount ? this.props.quote.totalsDiscount : '',
            discountPercentage: this.props.quote.discountPercent ? this.props.quote.discountPercent : '',
            shippingPrice: this.props.quote.shipping.shippingPrice !== null && this.props.quote.shipping.shippingPrice !== undefined
                ? this.props.quote.shipping.shippingPrice.toString()
                : '',
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.quote !== this.props.quote) {
            this.setState({
                discountValue: this.props.quote.totalsDiscount ? this.props.quote.totalsDiscount : '',
                discountPercentage: this.props.quote.discountPercent ? this.props.quote.discountPercent : '',
                shippingPrice: this.props.quote.shipping.shippingPrice !== null && this.props.quote.shipping.shippingPrice !== undefined
                    ? this.props.quote.shipping.shippingPrice.toString()
                    : '',
            });
        }
    }

    handleDiscountValueChange = (value) => {
        this.setState({ discountValue: value });
    };

    handleDiscountPercentageChange = (value) => {
        this.setState({ discountPercentage: value });
    };

    updateValue = (value, keyArray, decimalPlaces = 2) => {
        const parsedValue = parseFloat(value);
        const validValue = isNaN(parsedValue) ? 0 : parsedValue.toFixed(decimalPlaces);

        this.props.patchQuote({
            quote: this.props.quote,
            keyArray: keyArray,
            newValue: validValue,
        });
    };

    handleDiscountValueBlur = () => {
        const { discountValue } = this.state;
        this.updateValue(discountValue, ['totalsDiscount'], 2);
    };

    handleDiscountPercentageBlur = () => {
        const { discountPercentage } = this.state;
        const percentageValue = parseFloat(discountPercentage) / 100;
        this.updateValue(percentageValue, ['discountPercent'], 4);
    };

    handleDiscountPercentageBlur = () => {
        const { discountPercentage } = this.state;
        this.updateValue(discountPercentage, ['discountPercent'], 0);
    };

    handleShippingBlur = (value) => {
        const { shippingPrice } = this.state;
        const newValue = parseFloat(shippingPrice) || 0;
        this.props.patchQuote({
            quote: this.props.quote,
            keyArray: ['shipping', 'shippingPrice'],
            newValue: newValue,
        });
    };


    handleShippingChange = (value) => {
        this.setState({ shippingPrice: value });
    };

    handleCurrencyChange = (newCurrency) => {
        const { quote } = this.props;
        const { grandTotal } = quote.totals || {};

        if (grandTotal > 0) {
            if (window.confirm("Changing the currency label will not convert the total value. Are you sure?")) {
                this.props.patchQuote({
                    quote: quote,
                    keyArray: ['currencyCode'],
                    newValue: newCurrency,
                });
            } else {
                // For if the user hits cancel, don't do anything
                return;
            }
        } else {
            this.props.patchQuote({
                quote: quote,
                keyArray: ['currencyCode'],
                newValue: newCurrency,
            });
        }
    };

    render() {
        const { lineItems, quoteToolClient, quote } = this.props;
        const { discountValue, discountPercentage } = this.state;
        const currencyFormatter = quote.getCurrencyFormatter().formatter;
        const currencyCode = quote.currencyCode;
        const profitClass = quote.profitTotal >= 0 ? 'profit-positive' : 'profit-negative';
        const isUSStore = quote.storeId === 1000104959;

        return (
            <div className="product-form-container">
                <div className="small-form-container ms-5 me-5">
                    <h3>Items</h3>
                    <form>
                        <table className="table form-table">
                            <thead>
                            <tr>
                                <th style={{width: '3%'}}>#</th>
                                <th style={{width: '5%'}}>SKU</th>
                                <th style={{width: '17%'}}>Title</th>
                                <th style={{width: '13%'}}>Quantity</th>
                                <th style={{width: '7%'}}>Inventory Level (Remaining)</th>
                                <th style={{width: '5%'}}>Unit Cost</th>
                                <th style={{width: '5%'}}>Retail Price</th>
                                <th style={{width: '8%'}}>Discount</th>
                                <th style={{width: '8%'}}>Price ($)</th>
                                <th>Profit</th>
                                <th>Extended (Subtotal)</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className="quote-line-item-table-compact">
                            {lineItems.map((lineItem, index) => (
                                <QuoteLineItemRow
                                    key={index}
                                    lineItem={lineItem}
                                    index={index}
                                    lineNumber={index + 1}
                                    quoteToolClient={quoteToolClient}
                                    patchQuote={this.props.patchQuote}
                                    patchQuoteAction={this.props.patchQuoteAction}
                                    patchLineItem={this.props.patchLineItem}
                                    setLineItem={this.props.setLineItem}
                                    quote={quote}
                                    isUSStore={isUSStore}
                                    storeId={this.props.storeId}
                                    updateValue={this.updateValue}
                                />
                            ))}
                            <tr>
                                <td colSpan="9"></td>
                                <td className="text-right">Subtotal:</td>
                                <td className="text-right">{currencyFormatter(quote.lineItemSubtotal)} {currencyCode}</td>
                            </tr>
                            <tr>
                                <td colSpan="9"></td>
                                <td className="text-right">Shipping:</td>
                                <td className="text-right">
                                    <NumericInput
                                        value={this.state.shippingPrice}
                                        onChange={this.handleShippingChange}
                                        onBlur={this.handleShippingBlur}
                                        className="text-right"
                                        style={{
                                            width: "30%",
                                            minWidth: '8ch',
                                        }}
                                        decimalPlaces={2}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="9"></td>
                                <td className="text-right">Tax:</td>
                                <td className="text-right">
                                    {quote.totals.taxes !== undefined ? currencyFormatter(quote.totals.taxes) : "N/A"} {currencyCode}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="9"></td>
                                <td className="text-right">Discount:</td>
                                <td className="text-right">
                                    <InputGroup style={{maxWidth: "170px"}}>
                                        <InputGroupText>$</InputGroupText>
                                        <NumericInput
                                            value={discountValue !== null ? discountValue : ''}
                                            onChange={this.handleDiscountValueChange}
                                            onBlur={this.handleDiscountValueBlur}
                                            className="text-right"
                                            style={{width: "40%"}}
                                            placeholder="0"
                                            decimalPlaces={2}
                                        />
                                    </InputGroup>
                                    <InputGroup style={{maxWidth: "170px"}}>
                                        <NumericInput
                                            value={discountPercentage !== null ? discountPercentage : ''}
                                            onChange={this.handleDiscountPercentageChange}
                                            onBlur={this.handleDiscountPercentageBlur}
                                            className="text-right"
                                            style={{width: "38%"}}
                                            placeholder="0%"
                                            decimalPlaces={0}
                                        />
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroup>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="9"></td>
                                <td className="text-right">Currency:</td>
                                <td className="text-right">
                                    <InputGroup size="sm" className="currency-dropdown">
                                        <Input
                                            type="select"
                                            value={quote.currencyCode}
                                            onChange={(e) => this.handleCurrencyChange(e.target.value)}
                                        >
                                            <option value="USD">USD</option>
                                            <option value="CAD">CAD</option>
                                        </Input>
                                    </InputGroup>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan="9"></td>
                                <td className="text-right">Grand Total:</td>
                                <td className="text-right">{currencyFormatter(quote.grandTotal)} {currencyCode}</td>
                            </tr>
                            <tr>
                                <td colSpan="9"></td>
                                <td className="text-right">Total Profit:</td>
                                <td className={`text-right ${profitClass}`}>{currencyFormatter(quote.profitTotal)} {currencyCode}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="text-start mt-3">
                            <Button
                                className="add-line-item-button"
                                onClick={() => {
                                    this.props.patchQuoteAction({ action: "addItem", data: {}, quote: this.props.quote });
                                }}
                            >
                                Add Line Item
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

ProductForm.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
    storeId: PropTypes.number.isRequired,
    lineItems: PropTypes.array.isRequired,
    patchQuote: PropTypes.func.isRequired,
    patchQuoteAction: PropTypes.func.isRequired,
    patchLineItem: PropTypes.func.isRequired,
    setLineItem: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired,
    isUSStore: PropTypes.bool.isRequired
};

export default ProductForm;