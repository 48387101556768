import React, { useState } from 'react';
import PropTypes from "prop-types";
import Address from "../atoms/Address";
import AccountSearchModal from "../atoms/AccountSearchModal.js";
import {Button, Col, FormGroup, Input, InputGroup, Row, Container, FormFeedback, FormText, Alert} from "reactstrap";
import { DatePicker } from "reactstrap-date-picker";
import moment from "moment/moment";
import NewAccountModal from "../atoms/NewAccountModal.js";
import TaxableSwitch from "../atoms/TaxableSwitch.js";
import Account from "@kjdelectronics/ps-quotetool-domain/obj/account/Account.js";
import {Link} from "react-router-dom";
import AccountSearchInput from "../atoms/AccountSearchInput.js";

class QuoteDetail extends React.Component {
    constructor(props) {
        super(props);
        let defaultEmail = this.isLegacyQuoteWithNoAccount ? this.primaryContactEmail : "";

        this.state = {
            accountEmailInputValue: defaultEmail,
            isAccountSearchModalOpen: false,
            isNewAccountModalOpen: false,
            invalidCustomer: false,
            searchResults: [],
            newCustomerEmail: '',
        };
    }

    componentDidMount() {
        if(this.state.accountEmailInputValue)
            this.handleAccountSearch(false);
    }

    get isLegacyQuoteWithNoAccount(){
        return this.props.quote.ref && !this.props.quote.account.id && this.primaryContactEmail !== "";
    }

    get primaryContactEmail(){
        if(this.props.quote.account?.contacts.length === 0)
            return "";
        return this.props.quote.account.contacts[0].email;
    }

    setAccount = async (selectedAccount) => {
        await this.setState({
            isNewAccountModalOpen: false,
            isAccountSearchModalOpen: false,
            accountEmailInputValue: "",
            newCustomerEmail: "",
        });
        this.props.patchQuote({
            keyArray: ['account'],
            newValue: selectedAccount
        });
    };

    handleAccountSearch = async (openModal=true) => {
       const searchResults = await this.props.quoteToolClient.getAccounts(this.state.accountEmailInputValue).catch(_404ErrorHandler);

       console.log(searchResults);

        if (searchResults.length === 1) {
            this.setAccount(searchResults[0]);
        } else if(openModal && searchResults.length === 0){
            //New Account page
            this.setState({
                searchResults,
                isNewAccountModalOpen: true
            });
        } else {
            this.setState({
                searchResults,
                isAccountSearchModalOpen: openModal && searchResults.length !== 0
            });
        }
    };

    handleEmailChange = (event) => {
        const email = event.target.value;
        this.setState({ accountEmailInputValue: email, searchResults: null });
    };

    toggleNewAccountModal = () => {
        this.setState({ isNewAccountModalOpen: !this.state.isNewAccountModalOpen });
        console.log("changing the state of the New Customer Modal...");
    };

    handleAccountSelected = (newAccount) => {
        this.setAccount(newAccount);
    };

    get showAccountSearchInputResultsWarning(){
        return this.state.accountEmailInputValue &&
            this.state.searchResults && this.state.searchResults.length !== 1;
    }

    render() {
        if (!this.props.quote) {
            throw new Error("Must provide quote as a param to QuoteDetail");
        }

        return (
            <div className="ms-3 me-3 small-form-container">
                <h3>Quote Detail</h3>
                <div className="row mt-1">
                    <div className="col-md-6">
                        {/* Left Column */}
                        <div className="mb-3">
                            {this.isLegacyQuoteWithNoAccount &&
                                <div className="text-center">
                                    <Alert className="text-center mb-0" color="danger">
                                    <strong>Account Required: </strong>This quote was created prior to implementation of the Accounts system. Search for an existing account
                                    or click New Account.
                                </Alert>
                                <span className="text-center fw-bold" style={{color: "grey"}}>Legacy Customer Email: {this.primaryContactEmail}</span>
                                </div>
                        }
                            {/* Customer Email */}
                            <div className="form-group row align-items-center mb-1">
                                <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Account<span
                                    style={{color: 'red'}}>*</span></label>
                                <div className="col-sm-9">
                                    <Row>
                                        <Col xs={12} sm={8}>
                                            {!this.props.quote.account?.id && (
                                                <InputGroup>
                                                    <AccountSearchInput
                                                        value={this.state.accountEmailInputValue}
                                                        invalid={this.state.invalidCustomer && !this.state.isAccountSearchModalOpen}
                                                        handleChange={e => this.handleEmailChange(e)}
                                                        triggerAccountSearchCallback={({openModal}) => this.handleAccountSearch(openModal)}/>
                                                    <Button type="button" className="btn btn-primary rounded mb-2 mb-sm-0" onClick={this.handleAccountSearch}>
                                                        Search
                                                    </Button>
                                                    <FormFeedback>
                                                        Account not found. Enter exact contact email, account name, or account number.
                                                    </FormFeedback>
                                                    {false && <FormText>
                                                        Email, account name, or account name
                                                    </FormText>}
                                                    {this.showAccountSearchInputResultsWarning && <FormText className="warning-color" >
                                                        <div>{this.state.searchResults.length} Account{this.state.searchResults.length !== 1 ? 's' : ""} found for this search.
                                                            {this.state.searchResults.length > 1 ? " Press Enter to see results" : " Press Enter to Create a New Account"}
                                                        </div>
                                                    </FormText>}
                                                </InputGroup>
                                            )}
                                            {this.props.quote.account?.id &&
                                                <div className="text-center">
                                                    <span> {this.props.quote.account.toString()} </span>
                                                    <Link className="navbar-link" target="_blank" to={`/accounts/${this.props.quote.account.id}`}><Button color="link">{this.props.quote.account.formattedShortCode}</Button></Link>
                                                </div>
                                            }
                                        </Col>
                                        <Col xs={12} sm={4} className="">
                                            {!this.props.quote.account?.id ? (
                                                <Button type="button" className="new-customer-button mb-2 mb-sm-0" onClick={this.toggleNewAccountModal}>
                                                    New Account
                                                </Button>
                                            ) : (
                                                <Button type="button" className="btn btn-danger" onClick={() => {
                                                    this.setAccount(new Account({}));
                                                }}>
                                                    X
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <TaxableSwitch isTaxable={this.props.quote.detail.isTaxable} onChange={newValue => this.props.patchQuote({
                                keyArray: ["detail", "isTaxable"],
                                newValue
                            })} />
                        </div>
                    </div>
                    <div className="col-md-6" style={{marginLeft: '0px', paddingLeft: '100px'}}>
                        {/* Right Column */}
                        {/* Date */}
                        <Row className="mb-1">
                            <Col sm={3}>
                                <label htmlFor="quoteDate" className="">Date</label>
                            </Col>
                            <Col sm={5}>
                                <DatePicker
                                    id="quoteDate"
                                    value={this.props.quote.detail.date}
                                    showClearButton={false}
                                    onChange={value => this.props.patchQuote({
                                        keyArray: ["detail", "date"],
                                        newValue: new Date(value)
                                    })}
                                    dateFormat="YYYY/MM/DD" // Display in YYYY/MM/DD as per request
                                />
                            </Col>
                            <Col sm={4} className="ms-0">
                                <Button className="today-button mx-2 py-1 mt-1 ms-0" onClick={event => this.props.patchQuote({
                                    keyArray: ["detail", "date"],
                                    newValue: new Date()
                                })}>
                                    Today
                                </Button>
                            </Col>
                        </Row>
                        {/* Expiration */}
                        <Row>
                            <Col sm={3}>
                                <label htmlFor="expiresAt" className="col-sm-3 col-form-label">Expiration</label>
                            </Col>
                            <Col sm={5}>
                            <DatePicker
                                id="expiresAt"
                                value={this.props.quote.detail.expiresAt}
                                showClearButton={true}
                                onChange={value => this.props.patchQuote({
                                    keyArray: ["detail", "expiresAt"],
                                    newValue: value ? new Date(value) : null
                                })}
                                dateFormat="YYYY/MM/DD" // Updated Format on request
                            />
                        </Col>
                        <Col sm={4} className="ms-0">
                            <Button className="thirty-day-button mx-2 py-1 mt-1 ms-0"
                                    onClick={event => this.props.patchQuote({
                                        keyArray: ["detail", "expiresAt"],
                                        newValue: new moment().add(30, 'days').toDate()
                                    })}
                            >
                                30 Days
                            </Button>
                            <Button className="ninety-day-button mx-2 py-1 mt-1"
                                    onClick={event => this.props.patchQuote({
                                        keyArray: ["detail", "expiresAt"],
                                        newValue: new moment().add(90, 'days').toDate()
                                    })}
                            >
                                90 Days
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>

        <AccountSearchModal
            isOpen={this.state.isAccountSearchModalOpen}
            onClose={() => this.setState({isAccountSearchModalOpen: false})}
            onAccountSelected={selectedAccount => this.setAccount(selectedAccount)}
            searchResults={this.state.searchResults}
            onAddNewAccount={() => this.toggleNewAccountModal()}
            email={this.state.accountEmailInputValue}
        />

        {this.state.isNewAccountModalOpen && <NewAccountModal
            isOpen={this.state.isNewAccountModalOpen}
            onClose={this.toggleNewAccountModal}
            onAccountSelected={(newAccount) => this.handleAccountSelected(newAccount)}
            quoteToolClient={this.props.quoteToolClient}
            email={this.state.accountEmailInputValue}
            storeId={this.props.quote.storeId}
        />}

        <Container className="address-container">
            <Row className="mt-3 justify-content-start">
                <Col xs={12} md={5}>
                    <Address
                        address={this.props.quote.addresses.shippingAddress}
                        addressType="shippingAddress"
                        storeId={this.props.storeId}
                        patchQuote={this.props.patchQuote}
                        header={"Shipping Address"}
                        isShippingAddressSameAsBillingChecked={this.props.quote.addresses.isBillingSameAsShippingAddress}
                        handleIsShippingAddressSameAsBillingChange={value => this.props.patchQuote({ keyArray: ["addresses", "isBillingSameAsShippingAddress"], newValue: value })}
                    />
                </Col>
                <Col xs={12} md={1}></Col> {/* Spacer column, for adjusting space in between Shipping Address and Billing Address */}
                <Col xs={12} md={5}>
                    <Address
                        address={this.props.quote.addresses.billingAddress}
                        addressType="billingAddress"
                        storeId={this.props.storeId}
                        header={"Billing Address"}
                        patchQuote={this.props.patchQuote}
                        blockTab={this.props.quote.addresses.isBillingSameAsShippingAddress}
                    />
                </Col>
            </Row>
    </Container>
    </div>
    );
    }
}

//TODO Move to common
function _404ErrorHandler(err){
    if(err.code === 404)
        return []
    throw err;
}

QuoteDetail.propTypes = {
    quote: PropTypes.object.isRequired,
    patchQuote: PropTypes.func.isRequired,
    patchQuoteAction: PropTypes.func.isRequired,
    quoteToolClient: PropTypes.object.isRequired,
};

export default QuoteDetail;