import React, { useState, useEffect, useContext } from 'react';
import AppContext from "../../context/AppContext";
import Select from 'react-select';

const customStyles = {
    control: (provided) => ({
        ...provided,
        borderColor: '#264653',
        boxShadow: 'none',
        minHeight: '30px',
        height: '30px',
        borderRadius: '0.25rem',
        '&:hover': {
            borderColor: '#80bdff',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '30px',
        padding: '0 8px',
    }),
    input: (provided) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '30px',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '5px',
        borderRadius: '0.3rem',
    }),
};

const CountryDropdown = ({ selectedCountry, selectedSubdivision, onCountryChange, onSubdivisionChange, isSubdivision, defaultCountry }) => {
    const { countryData } = useContext(AppContext);
    const [subdivisions, setSubdivisions] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [filteredSubdivisions, setFilteredSubdivisions] = useState([]);
    const [countryInputValue, setCountryInputValue] = useState('');
    const [subdivisionInputValue, setSubdivisionInputValue] = useState('');

    useEffect(() => {
        if (!selectedCountry && defaultCountry && countryData) {
            const defaultCountryOption = countryData.countries.find(
                (country) => country.countryIso2 === defaultCountry
            );

            if (defaultCountryOption && onCountryChange) {
                onCountryChange({ country: defaultCountry });
            }
        }

        if (selectedCountry && countryData) {
            // Fetch subdivisions for the selected country
            const countrySubdivisions = countryData.countrySubdivisions
                .filter(sub => sub.countryCode === selectedCountry)
                .map(sub => ({
                    value: sub.code,
                    label: sub.subdivisionName
                }));
            setSubdivisions(countrySubdivisions);
            setFilteredSubdivisions(countrySubdivisions);
        }
    }, [selectedCountry, countryData, defaultCountry, onCountryChange]);

    const handleCountryChange = (selectedOption) => {
        const country = selectedOption ? selectedOption.value : '';
        if (country !== selectedCountry) {
            setSubdivisions([]);
            setFilteredSubdivisions([]);
            setCountryInputValue('');
            onCountryChange({ country });
        }
    };

    const handleSubdivisionChange = (selectedOption) => {
        const subdivision = selectedOption ? selectedOption.value : '';
        setSubdivisionInputValue('');
        onSubdivisionChange({ stateProvince: subdivision });
    };

    const handleCountryInputChange = (newValue, { action }) => {
        if (action === 'input-change') {
            setCountryInputValue(newValue);
            if (!newValue) {
                setFilteredCountries([]);
                return;
            }

            // Adds ISO2 exact match
            const exactMatch = countryData.countries.find(
                country => country.countryIso2.toLowerCase() === newValue.toLowerCase()
            );

            if (exactMatch) {
                setFilteredCountries([{
                    value: exactMatch.countryIso2,
                    label: `${exactMatch.country} (${exactMatch.countryIso2})`
                }]);
            // Otherwise default to existing logic filter
            } else {
                const regex = new RegExp(`^${newValue}`, 'i');
                const filtered = countryData.countries.filter(country =>
                    regex.test(country.country)
                ).map(country => ({
                    value: country.countryIso2,
                    label: `${country.country} (${country.countryIso2})`
                }));

                setFilteredCountries(filtered);
            }
        }
    };

    const handleSubdivisionInputChange = (newValue, { action }) => {
        if (action === 'input-change') {
            setSubdivisionInputValue(newValue);
            if (!newValue) {
                setFilteredSubdivisions(subdivisions);
                return;
            }

            const regex = new RegExp(`^${newValue}`, 'i');
            const filtered = subdivisions.filter(subdivision =>
                regex.test(subdivision.label)
            );

            setFilteredSubdivisions(filtered);
        }
    };

    if (!countryData || !countryData.countries) {
        return <p>Loading...</p>; // Could alternatively do a spinner or something of the sort
    }

    const sortedCountries = countryData.countries.sort((a, b) => {
        if (a.country === "Canada") return -1;
        if (b.country === "Canada") return 1;
        if (a.country === "United States") return -1;
        if (b.country === "United States") return 1;
        return a.country.localeCompare(b.country);
    }).map(country => ({
        value: country.countryIso2,
        label: `${country.country} (${country.countryIso2})`
    }));

    return (
        <div>
            {isSubdivision ? (
                <Select
                    id="subdivision"
                    styles={customStyles}
                    value={filteredSubdivisions.find(option => option.value === selectedSubdivision) || null}
                    onChange={handleSubdivisionChange}
                    onInputChange={handleSubdivisionInputChange}
                    inputValue={subdivisionInputValue}
                    options={filteredSubdivisions.length ? filteredSubdivisions : subdivisions}
                    placeholder="State/Province"
                    filterOption={() => true}
                    onBlur={() => setSubdivisionInputValue('')}
                />
            ) : (
                <Select
                    id="country"
                    styles={customStyles}
                    value={sortedCountries.find(option => option.value === selectedCountry) || null}
                    onChange={handleCountryChange}
                    onInputChange={handleCountryInputChange}
                    inputValue={countryInputValue}
                    options={filteredCountries.length ? filteredCountries : sortedCountries}
                    placeholder="Country"
                    filterOption={() => true}
                    onBlur={() => setCountryInputValue('')}
                />
            )}
        </div>
    );
};

export default CountryDropdown;
