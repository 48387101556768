import React from 'react';
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Tooltip } from 'reactstrap';
import LockableLineItemField from "../atoms/LockableLineItemField.js";
import QuoteLineItem from "@kjdelectronics/ps-quotetool-domain/obj/quote/QuoteLineItem"
import { Button, FormFeedback, Input, InputGroup, InputGroupText, Badge } from "reactstrap";
import TitleSearchModal from "../atoms/TitleSearchModal";
import { percentFormatter, usdFormatter, cadFormatter, eurFormatter, gbpFormatter } from "../../helper/formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faBoxOpen, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ButtonWithToolTip from "../atoms/ButtonWithTooltip";
import ProductPhysicalDetailsModal from "../atoms/ProductPhysicalDetailsModal";
import NumericInput from "../atoms/NumericInput";

class QuoteLineItemRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locked: !!props.lineItem.productId,
            loading: false,
            productSearchResults: null,
            productDetails: null,
            isTitleSearchModalOpen: false,
            isProductDetailsModalOpen: false,
            filteredResults: [],
            userInput: '',
            skuSearchTimeout: null,
            tooltipOpen: false,
            quantityInput: props.lineItem.quantity.toString(),
            quantityError: false,
            editingDiscount: props.lineItem.discount,
            editingPrice: props.lineItem.price ? props.lineItem.price.toString() : '',
        };

        this.handleSkuKeyDown = this.handleSkuKeyDown.bind(this);
        this.handleDiscountChange = this.handleDiscountChange.bind(this);
    }

    async handleLineItemChange(index, field, value) {
        this.props.patchLineItem({ lineItemIndex: index, key: field, value });
        if (field === 'sku') {
            clearTimeout(this.state.skuSearchTimeout);
            if (!value.trim()) {
                return;
            }
            this.setState({
                skuSearchTimeout: setTimeout(() =>
                        this._handleProductSkuChanged({
                                index,
                                sku: value
                            },
                            { requireSkuFieldToMatch: true }
                        ).catch(err => {
                            // No further error processing needed
                        }),
                    250)
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lineItem.price !== this.props.lineItem.price) {
            this.setState({
                editingPrice: this.props.lineItem.price ? this.props.lineItem.price.toString() : '',
            });
        }
    }


    toggleProductDetailsModal = () => {
        this.setState(prevState => ({
            isProductDetailsModalOpen: !prevState.isProductDetailsModalOpen
        }));
    };

    //TODO Move this to domain
    async _buildNewQuoteLineItemFromApiData({ sku, product }) {
        console.log('Product data:', product);
        if (!product && this.props.lineItem.productId) // If there was an actual product selected before clear it
            return new QuoteLineItem({
                productId: null,
                sku: sku,
                title: "",
                quantity: 1,
            }, this.props.quote);
        else {
            console.log(product);
            const productId = product.product_variant.product_id;
            //Get the product card. This is the same method on the backend to ensure same result
            //TODO This is not actually implemented in the new API.
            const productCard = await this.props.quoteToolClient.getSaturnDataBySaturnProductIdForStoreId({saturnProductId: productId, storeId: this.props.quote.storeId})
                .catch(err => {
                    console.log("Failed to get product card", err); //This is not the end of the world, just won't have inventory data
                    return []; //Fail with an empty list
                })

            const lineItem = new QuoteLineItem({
                productId: productId,
                sku: product.sku,
                title: product.name,
                quantity: 1,
                unitCostUsd: product?.product_warehouse?.cost_price,
                retailPrice: product.price,
                price: product.price,
                discount: 0,
                saturnData: {_raw: productCard }
            }, this.props.quote);
            console.log(lineItem);
            return lineItem;
        }
    }

    /**
     *
     * @param index
     * @param sku
     * @param requireSkuFieldToMatch This additional option was added so that it can be set to true from the
     * handleLineItemChange method triggered when the user physically types into the SKU field. This adds an additional
     * check that prevents the application from accepting a SKU that has since been changed.
     * @returns {Promise<void>}
     * @private
     */
    async _handleProductSkuChanged({ index, sku }, { requireSkuFieldToMatch = false } = {}) {
        try {
            const product = await this.props.quoteToolClient.getSaturnProductBySkuForStoreId({sku, storeId: this.props.quote.storeId});

            if (requireSkuFieldToMatch && this.props.lineItem.sku !== sku)
                return; // The user is typing into this field and the current result no longer matches what they are typing. Disregard this result
            const newLineItemObject = await this._buildNewQuoteLineItemFromApiData(
                { sku, product });

            this.props.setLineItem({ lineItemIndex: index, newLineItemObject });

            this.props.patchLineItem({ lineItemIndex: index, key: 'weight', value: product.properties.weight });
            this.props.patchLineItem({
                lineItemIndex: index,
                key: 'dimensions',
                value: {
                    width: product.dimensions.width,
                    height: product.dimensions.height,
                    depth: product.dimensions.depth
                }
            });

            this.setState({ productDetails: product, });
        } catch (error) {
            if (error.code === 404) {
                this.props.patchLineItem({ lineItemIndex: index, key: 'title', value: '' });
                this.props.patchLineItem({ lineItemIndex: index, key: 'productId', value: null });
                return;
            }

            if (requireSkuFieldToMatch && this.props.lineItem.sku !== sku) {
                return;
            }

            console.error('Error searching for product:', error.message);
            if (error.structuredErrorData) {
                //TODO move to Atom
                const errorData = <div><b>MISSING DATA</b><br />Failed to Get:
                    <ul>
                        {error.structuredErrorData.productData ? "" : <li>Product Data</li>}
                        {error.structuredErrorData.productWarehouse ? "" : <li>Warehouse Data</li>}
                        {error.structuredErrorData.productStore ? "" : <li>Store Data</li>}
                    </ul>
                </div>
                toast.error(errorData);
            } else {
                toast.error("Unable to populate product details for this product. Check your connection or contact support if error persists");
            }
            throw error;
        }
    }

    handleProductSelected = (sku, title) => {
        // When a product is selected from the search screen mark the page as loading to prevent changes
        //Then lookup the product by SKU and populate the data.
        //Finally mark loading as false. If no errors set locked as true
        this.setState({ loading: true }, async () => {
            let noError = true;
            try {
                await this._handleProductSkuChanged({ index: this.props.index, sku });
            } catch (err) {
                console.log(`Error while populating product from search. Row will not be locked`);
                noError = false;
            } finally {
                this.setState({ loading: false, locked: noError });
            }
        });
    };

    toggleTooltip = () => {
        this.setState(prevState => ({
            tooltipOpen: !prevState.tooltipOpen
        }));
    };

    handleTitleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            const inputValue = e.target.value.trim().toLowerCase();
            if (inputValue) {
                try {
                    const searchResults = await this.props.quoteToolClient.searchSaturnProductsByNameForStoreId({name: inputValue, storeId: this.props.quote.storeId});
                    console.log('Search Results', searchResults);
                    this.setState({
                        isTitleSearchModalOpen: searchResults.length > 0,
                        productSearchResults: searchResults
                    });
                } catch (error) {
                    toast.error(
                        <div>
                            <div>
                                <b>Product Search Error</b>
                            </div>
                            {error.userMessage}
                        </div>
                    );
                    console.error('Error searching for product:', error);
                }
            }
        }
    };

    // TODO: Currently Broken
    handleSkuKeyDown = async (e) => {
        if (e.key === 'Enter') {
            const inputValue = e.target.value.trim().toLowerCase();
            if (!inputValue) {
                return; // Do nothing if the input is empty
            }
            try {
                const searchResults = await this.props.quoteToolClient.getSaturnProductBySkuForStoreId({sku: inputValue, storeId: this.props.storeId});
                console.log('Search Results', searchResults);
                this.setState({ isTitleSearchModalOpen: searchResults && searchResults.length > 0, productSearchResults: searchResults });
            } catch (error) {
                if (inputValue) {
                    toast.error(
                        <div>
                            <div>
                                <b>Product Search Error</b>
                            </div>
                            {error.userMessage}
                        </div>
                    );
                    console.error('Error searching for product:', error);
                }
            }
        }
    };

    _searchButtonOnClick() {
        const titleValue = this.props.lineItem.title.trim().toLowerCase();
        if (titleValue) {
            this.handleTitleKeyDown({ key: 'Enter', target: { value: titleValue } });
        }
    }

    handleDiscountChange = (value) => {
        this.setState({ editingDiscount: value });
    };


    handleQuantityChange(index, value) {
        const quantity = parseInt(value, 10);
        const isOverQuantity = quantity > (this.props.lineItem.saturnData?.productWarehouse ? this.props.lineItem.saturnData?.productWarehouse.available : 0);

        this.props.patchLineItem({ lineItemIndex: index, key: 'quantity', value: quantity });

        this.setState({
            quantityError: isOverQuantity,
            quantityInput: quantity.toString()
        });
    }

    handlePriceBlur = (index) => {
        const { editingPrice } = this.state;
        const parsedValue = parseFloat(editingPrice);
        const valueToUpdate = isNaN(parsedValue) ? 0 : parsedValue;
        this.props.updateValue(valueToUpdate, ['lineItems', index, 'price'], 2);
    };

    handlePriceChange = (value) => {
        this.setState({ editingPrice: value });
    };


    handleDiscountBlur = (index) => {
        const { lineItem } = this.props;
        const discount = this.state.editingDiscount;
        this.props.updateValue(discount, ['lineItems', index, 'discount'], 0);
    };

    _renderAvailable(available){
        if(isNaN(available))
            return <span className="small-italic">Not Avail</span>
        return available !== "" ? `${available} (${available - this.props.lineItem.quantity})` : "";
    }

    render() {
        const { index, lineItem, quote, lineNumber, storeId } = this.props;

        const { productDetails, quantityError} = this.state;

        // Extract product store and determine prices, took a lot of debugging to figure out
        const productStore = lineItem.saturnData?._raw?.product_variants[0]?.product_warehouses_active.find(warehouse =>
            warehouse.product_stores.find(store => store.store_id === storeId)
        )?.product_stores.find(store => store.store_id === storeId);

        const regularPrice = productStore?.price || lineItem.retailPrice;
        const salePrice = productStore?.sale_price > 0 ? productStore?.sale_price : null;

        const isArchived = productStore && productStore.is_active === 0;

        let available;
        if(this.props.lineItem.productId)
            available = this.props.lineItem.saturnData?.productWarehouse?.available;
        else
            available = "";

        const { editingDiscount } = this.state;

        const currencyFormatter = usdFormatter; //quote.getCurrencyFormatter().formatter;
        const isDuplicate = quote.lineItems.filter(item => item.sku === lineItem.sku).length > 1;
        const profitClass = lineItem.profitTotal >= 0 ? 'profit-positive' : 'profit-negative';
        const inventoryClass = available - lineItem.quantity <= 0 ? 'profit-negative' : '';

        return (
            <tr key={index}>
                <td>{lineNumber}</td>
                <td>
                    <InputGroup>
                        <div className="sku-label">
                            <LockableLineItemField
                                value={lineItem.sku}
                                isLocked={this.state.locked}
                                autoFocusOnMount={true}
                                className="sku-label"
                                onBlurHandler={event => this.setState({locked: !!lineItem.productId})} /* When leaving the field only lock when a product has been selected (not for custom items) */
                                inputProperties={{
                                    type: "text",
                                    disabled: this.state.loading,
                                    invalid: false, //TODO Implement to show error when user enters invalid SKU (needs to be timed correctly)
                                    onChange: (e) => this.handleLineItemChange(index, 'sku', e.target.value),
                                    onKeyDown: this.handleSkuKeyDown,
                                    style: {color: isDuplicate ? 'red' : 'black'}
                                }}
                            />
                            {isDuplicate && (
                                <Badge color="danger" pill className="duplicate-sku-badge">
                                    Duplicate SKU
                                </Badge>
                            )}
                            {isArchived && (
                                <Badge color="danger" pill className="archived-sku-badge">
                                    Archived SKU
                                </Badge>
                            )}
                        </div>
                        {isDuplicate && <FormFeedback>Duplicate SKU</FormFeedback>}
                        <FormFeedback>
                            SKU not found
                        </FormFeedback>
                    </InputGroup>
                </td>
                <td>
                    <InputGroup>
                        <Input
                            type="text"
                            value={lineItem.title}
                            disabled={this.state.loading}
                            onChange={e => this.handleLineItemChange(this.props.index, 'title', e.target.value)}
                            onKeyDown={e => this.handleTitleKeyDown(e)}
                            invalid={this.state.productSearchResults && this.state.productSearchResults.length === 0}
                        />
                        <div className="input-group-append">
                            <ButtonWithToolTip
                                buttonProps={{
                                    outline: true,
                                    size: "sm",
                                    onClick: () => this._searchButtonOnClick(),
                                    className: "title-search-button"
                                }}
                                toolTipText="Click search or press enter to search"
                                buttonText="Search"
                                id={`search-btn-${this.props.index}`}
                            />
                        </div>
                        <FormFeedback>
                            No products found
                        </FormFeedback>
                    </InputGroup>
                    {this.state.isTitleSearchModalOpen && (
                        <TitleSearchModal
                            isOpen={this.state.isTitleSearchModalOpen}
                            productsData={this.state.productSearchResults}
                            onClose={() => this.setState({isTitleSearchModalOpen: false})}
                            onProductSelected={this.handleProductSelected}
                        />
                    )}
                    <ProductPhysicalDetailsModal
                        isOpen={this.state.isProductDetailsModalOpen}
                        toggle={this.toggleProductDetailsModal}
                        product={this.state.productDetails || lineItem}
                        quote={quote}
                    />
                </td>
                <td>
                    <InputGroup size="sm" style={{whiteSpace: 'nowrap', display: 'flex'}}>
                        <Button
                            className="quantity-subtract-button"
                            tabIndex="-1"
                            onClick={() =>
                                this.handleQuantityChange(
                                    index,
                                    Math.max(0, lineItem.quantity - 1)
                                )
                            }
                        >
                            -
                        </Button>
                        <Input
                            type="text"
                            className="text-center"
                            value={this.state.quantityInput}
                            onChange={(e) => this.handleQuantityChange(index, e.target.value)}
                            style={{
                                borderColor: quantityError ? 'red' : 'initial',
                                minWidth: '6ch',
                                maxWidth: '70px',
                            }}
                        />
                        <Button
                            className="quantity-add-button"
                            tabIndex="-1"
                            onClick={() =>
                                this.handleQuantityChange(index, lineItem.quantity + 1)
                            }
                        >
                            +
                        </Button>
                    </InputGroup>
                </td>

                <td className={`inventory-level ${inventoryClass}`}>
                    {
                        this._renderAvailable(available)
                    }
                </td>
                <td>
                    <LockableLineItemField
                        value={lineItem.unitCost}
                        lockedFormatter={currencyFormatter}
                        isLocked={!!this.props.lineItem.productId}
                        inputProperties={{
                            type: "number",
                            onChange: (e) => this.handleLineItemChange(index, 'unitCostUsd', parseFloat(e.target.value))
                        }}
                    />
                </td>
                <td>
                    {salePrice ? (
                        <>
                            <LockableLineItemField
                                value={salePrice}
                                lockedFormatter={currencyFormatter}
                                isLocked={!!this.props.lineItem.productId}
                                inputProperties={{
                                    type: "number",
                                    onChange: (e) => this.handleLineItemChange(index, 'retailPrice', parseFloat(e.target.value))
                                }}
                                className="sale-price"
                            />
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                id={`tooltip-${index}`}
                                style={{marginLeft: '5px', color: 'blue', cursor: 'pointer'}}
                            />
                            <Tooltip
                                placement="top"
                                isOpen={this.state.tooltipOpen}
                                target={`tooltip-${index}`}
                                toggle={this.toggleTooltip}>
                                Promotional Price! Normally: ${regularPrice}
                            </Tooltip>
                        </>
                    ) : (
                        <LockableLineItemField
                            value={lineItem.retailPrice}
                            lockedFormatter={currencyFormatter}
                            isLocked={!!this.props.lineItem.productId}
                            inputProperties={{
                                type: "number",
                                onChange: (e) => this.handleLineItemChange(index, 'retailPrice', parseFloat(e.target.value))
                            }}
                        />
                    )}
                </td>
                <td>
                    <InputGroup>
                        <InputGroupText>%</InputGroupText>
                        <NumericInput
                            value={this.state.editingDiscount}
                            onChange={this.handleDiscountChange}
                            onBlur={() => this.handleDiscountBlur(index)}
                            decimalPlaces={0}
                            className="text-center"
                            style={{minWidth: '4ch', maxWidth: '70px'}}
                        />
                    </InputGroup>
                </td>
                <td>
                    <InputGroup>
                        <InputGroupText>$</InputGroupText>
                        <NumericInput
                            value={this.state.editingPrice}
                            onChange={this.handlePriceChange}
                            onBlur={() => this.handlePriceBlur(index)}
                            style={{ minWidth: '5ch', maxWidth: '100px' }}
                        />

                    </InputGroup>
                </td>
                <td className={profitClass}>{currencyFormatter(lineItem.profitTotal)} ({percentFormatter(lineItem.profitPercentage)})</td>
                <td>{currencyFormatter(lineItem.extendedLineTotal)}</td>
                <td>
                    <div className="action-icons">
                    <Button
                            color="link"
                            style={{color: "#dc3545"}}
                            tabIndex="-1"
                            onClick={() => this.props.patchQuoteAction({action: "removeItem", data: {index}})}
                        >
                            <FontAwesomeIcon icon={faX} className="action-icon"/>
                        </Button>
                        <Button
                            color="link"
                            style={{color: "#007bff"}}
                            tabIndex="-1"
                            onClick={this.toggleProductDetailsModal}
                        >
                            <FontAwesomeIcon icon={faBoxOpen} className="action-icon"/>
                        </Button>
                    </div>
                </td>
            </tr>
        );
    }
}

QuoteLineItemRow.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
    lineItem: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    patchQuote: PropTypes.func.isRequired,
    patchQuoteAction: PropTypes.func.isRequired,
    patchLineItem: PropTypes.func.isRequired,
    setLineItem: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired,
    lineNumber: PropTypes.number.isRequired,
    storeId: PropTypes.number.isRequired,
    isUSStore: PropTypes.bool.isRequired
};

export default QuoteLineItemRow;