import React from 'react';
import PropTypes from 'prop-types';
import Navbar from "../molecules/QuoteToolNavBar";
import { QuoteOrganism } from "../organism/QuoteOrganism";
import withRouter from "../../helper/withRouter";
import { ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';
import Loading from "../molecules/Loading.js";
import AppContext from "../../context/AppContext.js";

const cookies = new Cookies();

class QuotePage extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            customerEmail: '',
            customerId: '',
            helpScoutTicket: '',
            storeId: null,
            storeName: null,
            store: null
        };
    }

    onQuoteCreated(newQuoteRef) {
        let path = this.props.router.location.pathname;
        if (path.slice(-1) === "/") //If last part of path is a / then remove it
            path = path.substring(0, path.length - 1);
        this.props.router.navigate(`${path}/${newQuoteRef}`);
    }

    render() {
        let existingQuoteId = parseInt(this.props?.router?.params?.quoteRef);
        let storeId = parseInt(this.props?.router?.params?.storeId);

        if (!storeId)
            return <Loading/>;

        const { currencyRates } = this.context;
        const queryParams = this.props?.router ? new URLSearchParams(this.props.router.location.search) : null;
        const customerEmail = queryParams?.get('customerEmail') || null;
        const helpScoutId = queryParams?.get('helpScoutId') || null;

        return (
            <div>
                <Navbar quoteId={existingQuoteId}
                        subHeading={existingQuoteId ? `Quote ${existingQuoteId}` : "New Quote"}
                        stores={this.state.stores}
                        storeId={storeId}
                />
                <QuoteOrganism
                    quoteToolClient={this.props.quoteToolClient}
                    heading={this.state.storeName}
                    existingQuoteRef={existingQuoteId}
                    key={existingQuoteId}
                    onQuoteCreated={newQuoteId => this.onQuoteCreated(newQuoteId)}
                    storeId={storeId}
                    customerEmail={customerEmail}
                    helpScoutId={helpScoutId}
                    store={this.state.store}
                    isUSStore={storeId === '1000104959'}
                    currencyRates={currencyRates}
                />
            </div>
        );
    }
}

QuotePage.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
};

export default withRouter(QuotePage);
