import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import {QuoteOrganism} from "../organism/QuoteOrganism";

class LoadingSpinner extends React.Component {
    render() {
        if(this.props.isLoading === false)
            return "";
        return (
            <div className={this.props.fullPage ? "loading-overlay" : ""}>
                <div className="loading-content">
                    <div className="spinner"></div>
                    <div className="spinner-message">
                        <h2 className="loading-text">{this.props.message}</h2>
                    </div>
                </div>
            </div>
        );
    }
}

LoadingSpinner.propTypes = {
    isLoading: PropTypes.bool,
    message: PropTypes.string,
    fullPage: PropTypes.bool,
};


export default LoadingSpinner;