import React from 'react';
import PropTypes from 'prop-types';

class NumericInput extends React.Component {
    handleInputChange = (e) => {
        const { value } = e.target;
        const { decimalPlaces, onChange } = this.props;

        const regex = decimalPlaces > 0
            ? new RegExp(`^\\d*(\\.\\d{0,${decimalPlaces}})?$`)
            : new RegExp(`^\\d*$`);

        if (regex.test(value)) {
            onChange(value);
        }
    };

    handleBlur = () => {
        const { value, decimalPlaces, onChange, onBlur } = this.props;

        const parsedValue = parseFloat(value);
        const committedValue = isNaN(parsedValue) ? '' : parsedValue.toFixed(decimalPlaces);

        onChange(committedValue);

        if (onBlur) {
            onBlur(committedValue);
        }
    };

    render() {
        const { value, className, style } = this.props;

        const displayValue = value !== null && value !== undefined ? value.toString() : '';

        return (
            <input
                type="text"
                value={displayValue}
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
                className={className}
                style={{
                    ...this.props.style,
                    borderRadius: '5px',
                }}
            />
        );
    }
}

NumericInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    decimalPlaces: PropTypes.number,
};

NumericInput.defaultProps = {
    decimalPlaces: 2,
};

export default NumericInput;
