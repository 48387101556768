import React, { useState, useEffect } from 'react';
import QuoteToolNavBar from "../molecules/QuoteToolNavBar.js";
import Loading from "../molecules/Loading.js";
import {BrowserRouter, Route, Routes} from "react-router-dom";

const LoadingPage = () => {

    const loading = () => {
        return <div>
            <QuoteToolNavBar/>
            <Loading/>
        </div>
    }
    //Note: This only reason this uses router is because quote tool nav bar needs to be in a router.
    //This is just so the page has some consistency as the loading should only show for a moment
    //This is an experiment may want to change this later
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="*/*"
                        element={loading}
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default LoadingPage;