import React from 'react';
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import CountryDropdown from './CountryDropdown';
import AppContext from "../../context/AppContext";

class Address extends React.Component {
    constructor(props, context) {
        super(props);
        this.state = {
            selectedCountry: props.address.country || '',
            selectedSubdivision: props.address.stateProvince || '',
        };

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleSubdivisionChange = this.handleSubdivisionChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.address.country !== prevState.selectedCountry || nextProps.address.stateProvince !== prevState.selectedSubdivision) {
            return {
                selectedCountry: nextProps.address.country || '',
                selectedSubdivision: nextProps.address.stateProvince || '',
            };
        }
        return null;
    }

    handleInputChange({ fieldName, value }) {
        console.log(`Updating field ${fieldName} with value ${value}`);
        this.props.patchQuote({
            keyArray: ["addresses", this.props.addressType, fieldName],
            newValue: value
        });
    }

    handleCountryChange({ country }) {
        console.log(`Country selected: ${country}`);
        this.setState({
            selectedCountry: country,
            selectedSubdivision: ''
        });
        this.handleInputChange({ fieldName: 'country', value: country });
    }

    handleSubdivisionChange({ stateProvince }) {
        console.log(`State/Province selected: ${stateProvince}`);
        this.setState({ selectedSubdivision: stateProvince });
        this.handleInputChange({ fieldName: 'stateProvince', value: stateProvince });
    }

    _getTabIndex() {
        if (this.props.blockTab)
            return { tabIndex: -1 };
    }

    render() {
        if (!this.props.address)
            throw new Error("Must provide address as a param to Address");

        return (
            <div className="address-container">
                <div className="form-column">
                    <h4>{this.props.header || "Address"}</h4>
                    <Row className="form-line input-group-sm">
                        <Col>
                            <div className="form-line input-group-sm">
                                <input type="text" className="form-control input-group-sm" placeholder="First Name"
                                       {...this._getTabIndex()}
                                       value={this.props.address.firstName}
                                       onChange={e => this.handleInputChange({
                                           fieldName: "firstName",
                                           value: e.target.value
                                       })}
                                /></div>
                        </Col>
                        <Col>
                            <div className="form-line input-group-sm">
                                <input type="text" className="form-control input-group-sm" placeholder="Last Name"
                                       {...this._getTabIndex()}
                                       value={this.props.address.lastName}
                                       onChange={e => this.handleInputChange({
                                           fieldName: "lastName",
                                           value: e.target.value
                                       })}
                                /></div>
                        </Col>
                    </Row>
                    <div className="form-line input-group-sm">
                        <input type="text" className="form-control input-sm" placeholder="Company/School"
                               {...this._getTabIndex()}
                               value={this.props.address.company}
                               onChange={e => this.handleInputChange({fieldName: "company", value: e.target.value})}
                        />
                    </div>
                    <div className="form-line input-group-sm">
                        <input type="text" className="form-control" placeholder="Address 1"
                               {...this._getTabIndex()}
                               value={this.props.address.line1}
                               onChange={e => this.handleInputChange({fieldName: "line1", value: e.target.value})}
                        />
                    </div>
                    <div className="form-line input-group-sm">
                        <input type="text" className="form-control" placeholder="Address 2"
                               {...this._getTabIndex()}
                               value={this.props.address.line2}
                               onChange={e => this.handleInputChange({fieldName: "line2", value: e.target.value})}
                        />
                    </div>
                    <Row className="form-line input-group-sm">
                        <Col>
                            <div className="form-line input-group-sm">
                                <input type="text" className="form-control"
                                       {...this._getTabIndex()}
                                       placeholder="City"
                                       value={this.props.address.city}
                                       onChange={e => this.handleInputChange({
                                           fieldName: "city",
                                           value: e.target.value
                                       })}
                                />
                            </div>
                        </Col>
                        <Col>
                            <CountryDropdown
                                selectedCountry={this.state.selectedCountry}
                                selectedSubdivision={this.state.selectedSubdivision}
                                onSubdivisionChange={this.handleSubdivisionChange}
                                defaultCountry={this.props.storeId === 1000104959 ? 'US' : this.props.storeId === 1000099708 ? 'CA' : ''}
                                isSubdivision={true}
                                className="form-control"
                            />
                        </Col>
                        <Col>
                            <div className="form-line input-group-sm">
                                <input type="text" className="form-control" placeholder="Postal Code"
                                       {...this._getTabIndex()}
                                       value={this.props.address.postalCode}
                                       onChange={e => this.handleInputChange({
                                           fieldName: "postalCode",
                                           value: e.target.value
                                       })}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className="form-line input-group-sm">
                        <Row>
                            <Col md={6}>
                                <div className="form-line input-group-sm">
                                    <label htmlFor="phone">Phone #</label>
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={this.props.address.phone} // Keep this unchanged
                                        onChange={e => this.handleInputChange({
                                            fieldName: "phone",
                                            value: e.target.value
                                        })}
                                        className="form-control"
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-line input-group-sm">
                                    <label htmlFor="country">Country</label>
                                    <CountryDropdown
                                        selectedCountry={this.state.selectedCountry}
                                        onCountryChange={this.handleCountryChange}
                                        defaultCountry={this.props.storeId === 1000104959 ? 'US' : this.props.storeId === 1000099708 ? 'CA' : ''}
                                        isSubdivision={false}
                                        className="form-control"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {this.props.addressType === "shippingAddress" &&
                        <div className="form-line input-group-sm">
                            <label>
                                <input
                                    type="checkbox"
                                    id="billingSameAsShipping"
                                    checked={this.props.isShippingAddressSameAsBillingChecked}
                                    onChange={e => this.props.handleIsShippingAddressSameAsBillingChange(e.target.checked)}
                                /> Is billing address the same as shipping address?
                            </label>
                        </div>}
                </div>
            </div>
        );
    }
}

Address.contextType = AppContext;

Address.propTypes = {
    address: PropTypes.object.isRequired,
    addressType: PropTypes.string.isRequired,
    patchQuote: PropTypes.func.isRequired,
    isShippingAddressSameAsBillingChecked: PropTypes.bool,
    handleIsShippingAddressSameAsBillingChange: PropTypes.func,
    header: PropTypes.string,
    blockTab: PropTypes.bool //If true tabIndex for all inputs will be -1 (blocked)
};

export default Address;
