import React from "react";
import QuoteToolNavBar from "../molecules/QuoteToolNavBar";
import withRouter from "../../helper/withRouter.js";

class AccountsPage extends React.Component {
    render() {
        return (
            <>
                <QuoteToolNavBar />
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                    <h1>Accounts</h1>
                    <p>Coming soon...</p>
                    <p>This page will list accounts</p>
                </div>
            </>
        );
    }
}

export default withRouter(AccountsPage);
