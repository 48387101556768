import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Row, Col } from "reactstrap";
import QuoteDetail from "../molecules/QuoteDetail";
import ProductForm from "../molecules/ProductForm";
import ShippingOptions from '../atoms/ShippingOptions';
import NotesField from '../atoms/NotesField';
import Quote from "@kjdelectronics/ps-quotetool-domain/obj/quote/Quote";
import PatchQuote from "@kjdelectronics/ps-quotetool-domain/domain/PatchQuote";
import LoadingSpinner from "../atoms/LoadingSpinner";
import { toast } from "react-toastify";
import PaymentAndGeneralTermsFlexibleDropdown from "../molecules/PaymentAndGeneralTermsFlexibleDropdown.js";
import AppContext from "../../context/AppContext.js";

class QuoteOrganism extends React.Component {
    constructor(props) {
        super(props);
        let errorMessage = null;
        if (this.props.existingQuoteRef && isNaN(this.props.existingQuoteRef))
            errorMessage = "Invalid Quote Id";

        this.state = {
            quote: new Quote({
                storeId: this.props.storeId,
                currencyRates: this.props.currencyRates,
                shipping: { shippingService: "" },
                customer: {
                    email: this.props.customerEmail,
                },
                detail: {
                    notes: "",
                    helpScoutId: this.props.helpScoutId,
                    includeBankingDetails: false
                },
                lineItems: [],
            }),
            hideTermsConditions: true,
            paymentOptions: [],
            isLoading: this.props.existingQuoteRef != null,
            errorMessage: errorMessage,
            loadingMessage: ""
        };

        // Bind patch functions to avoid using arrow functions and repassing of arguments
        this.patchQuote = this.patchQuote.bind(this);
        this.patchQuoteAction = this.patchQuoteAction.bind(this);
        this.patchLineItem = this.patchLineItem.bind(this);
        this.setLineItem = this.setLineItem.bind(this);
        this.handleNotesChange = this.handleNotesChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.saveAndOpenPdf = this.saveAndOpenPdf.bind(this);
        this.handleTermsChange = this.handleTermsChange.bind(this);
        this.handlePaymentTermsChange = this.handlePaymentTermsChange.bind(this);
        this.handleIncludeBankingDetailsChange = this.handleIncludeBankingDetailsChange.bind(this);
        this.refreshDataFromBc = this.refreshDataFromBc.bind(this);
    }

    async componentDidMount() {
        const ninetyDaysInMillis = 90 * 24 * 60 * 60 * 1000;
        const now = Date.now();

        let quoteState = {isLoading: false};
        let quote = null;
        if (this.props.existingQuoteRef && this.state.errorMessage === null)
            quote = await this.props.quoteToolClient.getQuoteByRef(this.props.existingQuoteRef)
                .catch(err => this.setState({ errorMessage: err.message ? err.message : "Error Loading Quote" }));

        if(quote)
            quoteState = {quote, ...quoteState};
        this.setState({ ...quoteState });
    }

    handleTitleChange = (lineItemIndex, selectedOption) => {
        // Update the title for the corresponding line item
        const { lineItems } = this.state.quote;
        const updatedLineItems = [...lineItems];
        updatedLineItems[lineItemIndex].title = selectedOption.label;

        // Update the quote with the modified line items
        const updatedQuote = { ...this.state.quote, lineItems: updatedLineItems };
        this.setState({ quote: updatedQuote });
    };

    patchQuote({ keyArray, newValue }) {
        this.setState({ quote: PatchQuote.patchQuote({ quote: this.state.quote, keyArray, newValue }) });
    }

    patchQuoteAction({ action, data }) {
        this.setState({ quote: PatchQuote.patchQuoteAction({ quote: this.state.quote, action, data }) },);
    }

    patchLineItem({ lineItemIndex, key, value }) {
        this.setState(prevState => ({
            quote: PatchQuote.patchLineItem({ quote: prevState.quote, lineItemIndex, key, value })
        }));
    }

    setLineItem({ lineItemIndex, newLineItemObject }) {
        this.setState({ quote: PatchQuote.setLineItem({ quote: this.state.quote, lineItemIndex, newLineItemObject }) });
    }

    handleTermsChange(event) {
        const selectedTermId = parseInt(event.target.value, 10);
        this.patchQuote({ keyArray: ['detail', 'termConditionId'], newValue: selectedTermId });
    }

    handlePaymentTermsChange(event) {
        const selectedTermId = parseInt(event.target.value, 10);
        this.patchQuote({ keyArray: ['detail', 'paymentTermId'], newValue: selectedTermId });
    }

    handleNotesChange(newNotes) {
        this.patchQuote({ keyArray: ['detail', 'notes'], newValue: newNotes });
    }

    handleIncludeBankingDetailsChange(event) {
        this.patchQuote({ keyArray: ['detail', 'includeBankingDetails'], newValue: event.target.checked });
    }

    async handleSubmit() {
        if(this.state.quote.account.getValidationErrors().isInvalid){
            toast.error(`Customer Account selection is required. Select an existing account or create a new one`, {
                autoClose: 2500,
            });
            return
        }

        try {
            const newQuote = await this.props.quoteToolClient.createUpdateQuote(this.state.quote);
            console.log(newQuote);

            if (!this.state.quote.ref) {
                this.props.onQuoteCreated(newQuote.ref);
            }
            else{
                this.setState({quote: newQuote});
            }

            toast.success(`Quote ${newQuote.ref} ${this.state.quote.id ? 'updated' : 'created'} successfully!`, {
                autoClose: 10000,
            });

            return newQuote;
        } catch (error) {
            console.error('Error creating/updating quote:', error);

            try {
                const userFriendlyErrors = JSON.parse(error.message);
                Object.values(userFriendlyErrors).forEach(message => {
                    toast.error(message);
                });
            } catch (parsingError) {
                toast.error('An unexpected error occurred. Please try again.');
            }

            return null;
        }
    }

    async saveAndOpenPdf() {
        const savedQuote = await this.handleSubmit();
        if (savedQuote && savedQuote.ref) {
            try {
                const pdfUrl = `/v1/quotes/${savedQuote.ref}.pdf`; // Relative URL to current page
                window.open(pdfUrl);
            } catch (error) {
                toast.error(`Failed to render PDF for quote ${savedQuote.id}`);
            }
        }
    }

    async refreshDataFromBc() {
        await this.setState(prevState => ({
            loadingMessage: "Please wait while product data is refreshed from BC. This may take some time especially for large quotes..."}
        ));
        const savedQuote = await this.handleSubmit();
        try {
            //TODO we should be able to set the data from here
            await this.props.quoteToolClient.syncProductDataIntoSaturnForQuoteRef(savedQuote.ref);
            //But the above does not seem to be working so we will just get the quote again
            const quote = await this.props.quoteToolClient.getQuoteByRef(this.props.existingQuoteRef)
                .catch(err => this.setState({ errorMessage: err.message ? err.message : "Error Loading Quote" }));
            this.setState({quote: quote, loadingMessage: ""});
            toast.success(`Product data updated from BC`);
        }catch (err){
            console.log(err);
            toast.error("Failed to update product data from BC");
            this.setState({loadingMessage: ""});
        }
    }


    render() {
        const { store, isUSStore } = this.props;
        const { hideTermsConditions } = this.state;

        if (this.state.errorMessage)
            return <Alert color="danger" className="mx-4 mt-3 text-center">{this.state.errorMessage}</Alert>;

        if (this.state.quote.storeId !== this.props.storeId)
            return <Alert color="danger" className="mx-4 mt-3 text-center">
                <span className="fw-bold">The Store ID in the URL does not match the Store ID of Quote #{this.state.quote.id}</span>
                <div className="mt-1">○
                    <br />Store ID of Quote: {this.state.quote.storeId}
                    <br />Store ID of URL: {this.props.storeId}
                </div>
            </Alert>;

        if(this.state.isLoading)
            return <LoadingSpinner isLoading={this.state.isLoading} message={this.state.loadingMessage}/>

        return (
            <div>
                {this.state.loadingMessage &&  <LoadingSpinner isLoading={true} fullPage={true} message={this.state.loadingMessage}/>}
                <QuoteDetail
                    quote={this.state.quote}
                    patchQuote={this.patchQuote}
                    patchQuoteAction={this.patchQuoteAction}
                    quoteToolClient={this.props.quoteToolClient}
                    storeId={this.props.storeId}
                    isUSStore={isUSStore}
                    handleIncludeBankingDetailsChange={this.handleIncludeBankingDetailsChange}
                    accountInvalid={this.state.accountInvalid}
                />
                <ProductForm
                    quoteToolClient={this.props.quoteToolClient}
                    lineItems={this.state.quote.lineItems}
                    patchQuote={this.patchQuote}
                    patchQuoteAction={this.patchQuoteAction}
                    patchLineItem={this.patchLineItem}
                    setLineItem={this.setLineItem}
                    quote={this.state.quote}
                    isUSStore={isUSStore}
                    storeId={this.props.storeId}/>
                <Row className="mt-3 ms-3 me-3 d-sm-block d-md-flex">
                    <Col md={10}>
                        <Row>
                            <Col md={4}>
                                <ShippingOptions
                                    value={this.state.quote.shipping.shippingService}
                                    onChange={value => this.patchQuote({ keyArray: ['shipping', 'shippingService'], newValue: value })}
                                    quote={this.state.quote}
                                    isUSStore={isUSStore}
                                />
                            </Col>
                            <Col md={4}>
                                <PaymentAndGeneralTermsFlexibleDropdown
                                    header="Payment Terms"
                                    selectedTerm={this.state.quote.detail.paymentTermId}
                                    onChange={this.handlePaymentTermsChange}
                                    quoteToolClient={this.props.quoteToolClient}
                                    termsOptions={this.state.paymentOptions}
                                />
                            </Col>
                            <Col md={4}>
                                <NotesField value={this.state.quote.detail.notes} onChange={this.handleNotesChange}/>
                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={this.state.quote.detail.includeBankingDetails}
                                        onChange={this.handleIncludeBankingDetailsChange}
                                        id="includeBankingDetailsCheckbox"
                                    />
                                    <label className="form-check-label" htmlFor="includeBankingDetailsCheckbox">
                                        Include Banking Details In PDF
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-1 ms-5 me-5">
                    <Col>
                        <Button
                            className="close-button-styles"
                            id="quoteSaveBtn"
                            onClick={this.handleSubmit}>{this.state.quote.id ? "Update Quote" : "Create Quote"}
                        </Button>
                        {this.state.quote.id &&
                            <Button
                                className="button-styles mx-2"
                                onClick={this.saveAndOpenPdf}>Save and View PDF
                            </Button>}
                        {this.state.quote.id &&
                            <Button
                                className="button-styles mx-2"
                                color="primary"
                                outline={true}
                                size="sm"
                                onClick={this.refreshDataFromBc}>Save and Refresh Product Data From BC
                            </Button>}
                    </Col>
                </Row>
            </div>
        );
    }
}

QuoteOrganism.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
    storeId: PropTypes.number.isRequired,
    existingQuoteRef: PropTypes.number,
    onQuoteCreated: PropTypes.func,
    customerEmail: PropTypes.string,
    helpScoutId: PropTypes.string,
    isUSStore: PropTypes.bool.isRequired,
    currencyRates: PropTypes.object.isRequired,
};

export { QuoteOrganism };