import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row } from 'reactstrap';
import PropTypes from "prop-types";

class AccountSearchModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: null, // Initialize searchResults state
        };
    }

    onAddNewCustomer = ( isOpen ) => {
        this.props.onAddNewCustomer(isOpen);
        console.log("Add new customer button clicked");
        this.props.onClose();
    };

    onClose = () => {
        console.log("Closing modal...");
        // Close the modal
        this.props.onClose();
    }

    render() {
        const { isOpen } = this.props;

        if(!isOpen)
            return "";

        return (
            <Modal isOpen={isOpen} size="lg" toggle={this.onClose} centered style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '900px', width: '100%'}}>
                <ModalHeader toggle={this.onClose}>Account Search <br/><span className="modal-sub-heading">Results for <i>{this.props.email}</i></span></ModalHeader>
                <ModalBody>
                    {this.props.searchResults  && this.props.searchResults.length > 0 ? (
                        <table style={{ width: '100%' }}>
                            <thead>
                            <tr style={{borderBottom: '3px solid #ccc'}}>
                                <th>Code</th>
                                <th>Account #</th>
                                <th>Account Name</th>
                                <th>Primary Email</th>
                                <th>Primary Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.searchResults.map((account, index) => (
                                <tr key={index} style={{borderBottom: '1px solid #ccc'}}>
                                    <td>
                                        <Button className="py-0" color="link"
                                                onClick={() => this.props.onAccountSelected(account)}>{account.formattedShortCode}</Button>
                                    </td>
                                    <td>{account.reference}</td>
                                    <td>{account.name}</td>
                                    <td>{account.contacts[0].email}</td>
                                    <td>{account.contacts[0].name}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No results found</p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={e => this.props.onAddNewAccount()} className="customer-search-button" style={{ marginRight: 'auto' }}>New Account</Button>
                    <Button onClick={this.onClose} className="close-button-styles">Close</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

AccountSearchModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAccountSelected: PropTypes.func.isRequired,
    onAddNewAccount: PropTypes.func.isRequired,
    searchResults: PropTypes.array,
    email: PropTypes.string.isRequired
};

export default AccountSearchModal;
